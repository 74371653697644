//paths
$fa-font-path: '/etc.clientlibs/common-ui/clientlibs/clientlib-site/resources/fonts/font-awesome/webfonts';
$klavika-font-path: '/etc.clientlibs/common-ui/clientlibs/clientlib-site/resources/fonts/klavika';
$open-sans-font-path: '/etc.clientlibs/common-ui/clientlibs/clientlib-site/resources/fonts/open-sans';
$imagesPath: 	'/etc.clientlibs/common-ui/clientlibs/clientlib-site/resources/imgs';

//corporate palette
$corporate-black: rgb(0,0,0);
$corporate-white: rgb(255,255,255);

//primary palette
$primary-red: rgb(237,28,36);
$primary-orange: rgb(242,101,34);
$primary-teal: rgba(0,194,222,1);
$primary-teal-dark: rgba(0,128,148,1);
$primary-magenta: rgba(209, 15, 61, 1);
$access-link-dark: rgba(0, 81, 198, 1);
$access-link-light: rgba(40, 153, 255, 1);
$primary-gold: rgba(193, 169, 104, 1);

//neutral
$neutral-10: rgb(241,241,242);
$neutral-20: rgb(225,225,226);
$neutral-40: rgb(157,159,162);
$neutral-50: rgba(116, 117, 121, 1);
$neutral-60: rgb(99,100,102);
$neutral-80: rgb(39,40,43);
$neutral-90: rgb(26,26,27);
$neutral-95: rgba(17, 17, 18, 1);


//amd primary tint
$primary-tint-red-80: rgb(241,73,80);
$primary-tint-red-60: rgb(244,119,124);
$primary-tint-red-30: rgb(250,186,189);
$primary-tint-orange-80: rgb(245,132,78);
$primary-tint-orange-60: rgb(247,163,122);
$primary-tint-orange-30: rgb(251,208,188);
$primary-tint-blue-80: rgb(71,232,255);
$primary-tint-blue-60: rgb(173,245,255);
$primary-tint-blue-30: rgb(204,249,255);

//amd colors
$light-blue: rgb(141,185,202);
$medium-blue: rgb(0,0,225);
$dark-blue: rgb(0,61,76);
$cyan-blue: rgb(0,112,201);
$moderate-orange: rgb(248,152,29);
$medium-orange: rgb(255,183,90);
$dark-red: rgb(157,0,20);
$gray: rgb(225,225,226);
$white-25: rgba(255,255,255,0.25);

//Toast color
$lightening-yellow: rgb(253,184,30);
$light-green: rgb(74, 165, 100);


// Element colors
$border-color: $corporate-black;
$border-color-light: $corporate-white;
$border-color-gray: rgb(191, 191, 200);

//fonts
$font-dark: $corporate-black;
$font-light: $corporate-white;

//button colors
$button-light-background: $corporate-black;
$button-light-background-hover: $corporate-white;
$button-light-border: $corporate-black;
$button-light-border-hover: $corporate-black;
$button-light-font: $corporate-white;
$button-light-font-hover: $corporate-black;

$button-dark-background: $corporate-white;
$button-dark-background-hover: $corporate-black;
$button-dark-border: $corporate-black;
$button-dark-border-hover: $corporate-black;
$button-dark-font: $corporate-black;
$button-dark-font-hover: $corporate-white;

//table
$table-border-color: $corporate-black;
$table-header: $corporate-black;
$table-hover-color: $light-blue;
$table-selected-color: $cyan-blue;

//header
$header-background: $corporate-black;
$header-font: $font-light;

//box shadows
$skim: 0 2px 6px 0 rgba(0, 0, 0, .24);
$lifted: 0 4px 10px 0 rgba(0, 0, 0, .24);
$raised: 0 6px 16px 0 rgba(0, 0, 0, .24);
$floating: 0 8px 28px 0 rgba(0, 0, 0, .24);
$shadow-field-focus: 0 0 0 0.15rem rgb(0, 0, 0, .25);

$box-shadow: $skim;
$box-shadow-sm: $lifted;
$box-shadow-lg: $raised;
$box-shadow-xl: $floating;

$border-radius-xl: 0.625rem;

//form checkbox
$form-check-input-checked-bg-color: $neutral-80;
$form-check-input-checked-border-color: $border-color;
$form-check-input-focus-border: $border-color;
$form-check-input-focus-box-shadow: $shadow-field-focus;

//icon size
$icon-size: (
	"sm": 1.625rem,
	"md": 2.125rem,
	"lg": 3.5rem
);
